<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
       <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul> 
      </div>
    </b-alert>
    <b-card title="Dealers">
      <b-row>
        <!-- Sell Type -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Sales Channel"
            label-cols-sm="5"
            label-align-sm="center"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="sellType"
              class="border mr-0"
              size="sm"
              :text="sellTypeName"
              variant="flat-primary"
            >
              <!-- <div style="max-height: 350px;"> -->
              <b-dropdown-item @click="changeSellType('All', 'all')">
                All
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeSellType(item.name, item.slug)"
                v-for="(item, index) in orderSellType"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
              <!-- </div> -->
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- Classification -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Sales Channel"
            label-cols-sm="5"
            label-align-sm="center"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="classification"
              class="border mr-0"
              size="sm"
              :text="classificationName"
              variant="flat-primary"
            >
              <!-- <div style="max-height: 350px;"> -->
              <b-dropdown-item @click="changeClassification('All', 'all')">
                All
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeClassification(item.name, item.slug)"
                v-for="(item, index) in orderClassification"
                :key="index"
              >
                {{ item.name }}
              </b-dropdown-item>
              <!-- </div> -->
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="fetchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height:2.142rem; line-height:0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="removeExitem"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>
        <b-col md="2" class="my-1">
          <!-- add new vendor button -->
          <div class="d-flex align-items-center ">
            <b-button
              style="height:2.142rem; line-height:0.5"
              v-if="authPermissions.includes('create-vendor')"
              variant="primary"
              :to="{ path: '/Vendor/Add' }"
            >
              <span class="text-nowrap">Add Dealer</span>
            </b-button>
          </div>
        </b-col>
        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="tagTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
          <template #cell(storename)="data">
             
             <span v-if="data.item.verified_at != null">
               <img
                 :src="require('@/assets/images/icons/verified-icon-small.png')"
                />
               </span>
                 {{ data.value }}


           </template>
            <template #cell(status)="data">
              <b-badge v-if="data.item.status_slug === 'active'" variant="primary">
                {{ data.value }}
              </b-badge>
              <b-badge v-if="data.item.status_slug === 'pending'" variant="success">
                {{ data.value }}
              </b-badge>
              <b-badge v-if="data.item.status_slug === 'disabled'" variant="warning">
                {{ data.value }}
              </b-badge>
              <b-badge v-if="data.item.status_slug === 'rejected'" variant="warning">
                {{ data.value }}
              </b-badge>
        
       
            </template>
            
            <template #cell(selling_type)="data">
              <b-badge
                v-if="data.item.is_sell_new"
                style="margin-bottom: 2px;"
                variant="success"
              >
                Portal
              </b-badge>
              <b-badge
                v-if="data.item.is_sell_used"
                variant="warning"
              >
                In Store
              </b-badge>
              
            </template>
            
            <template #cell(classification)="data">
              <b-badge
                v-if="data.item.portal"
                style="margin-bottom: 2px;"
                variant="success"
              >
                Portal
              </b-badge>
              <b-badge
                v-if="data.item.in_store"
                variant="warning"
              >
                In Store
              </b-badge>
              
            </template>
            <template #cell(actions)="items">
              <div>
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{
                    path: '/Vendor/Show/' + items.item.id,
                    params: { statusName: items.item.name },
                  }"
                >
                  <feather-icon
                    style="width:18px; height:18px;"
                    icon="EyeIcon"
                  />
                </b-button>
                <!-- export active variants-->
               
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  type="submit"
                  @click="exportActiveVariants(items.item)"
                >
                  <feather-icon
                    style="width:18px; height:18px;"
                    icon="DownloadIcon"
                  />
                </b-button>
                <!-- delete button -->
                <b-button
                  v-if="authPermissions.includes('delete')"
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  type="submit"
                  @click="deleteData(items.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width:18px; height:18px;"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: "created_at",
      sortDesc: true,
      sortDirection: "desc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      classificationName: 'All',
      classification: 'all',
      orderClassification: [
        {
          name: 'Portal',
          slug: 'portal',
        },
        {
          name: 'In Store',
          slug: 'in_store',
        },
      ],
      sellTypeName: 'All',
      sellType: 'all',
      orderSellType: [
        {
          name: 'Portal',
          slug: 'portal',
        },
        {
          name: 'In Store',
          slug: 'in_store',
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'storename',
          label: 'Store Name',
          class: 'th-spaces-update',
        },
        {
          key: "email",
          label: "Email",
          class: 'th-spaces-update',
        },

        {
          key: 'code',
          label: 'Code',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'status',
          label: 'Status',
          class: 'th-spaces-update',
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'selling_type',
          label: 'Selling Products',
          class: 'th-spaces-update',
        },
        {
          key: 'classification',
          label: 'Sales Channel',
          class: 'th-spaces-update',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
          class: 'th-spaces-update',
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      exportItems: [],
    };
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request tags index
    this.fetchData();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    sortDesc: function() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData();
    },
    sortBy: function() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData();
    },
    filter: function() {
      if (this.filter == "" || this.filter == null) {
        this.fetchData();
      }
    },
    classification: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sellType: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
  },
  methods: {
    // fetchData of vendors
    fetchData() {
      this.showDismissibleAlert = false;
      // request vendors index API
      var url = null;
      if (this.filter == "") {
        url =
          "vendors-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          '/' +
          this.sellType+
          '/' +
          this.classification+
          "?page=" +
          this.currentPage;
      } else {
        url =
          "vendors-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          '/' +
          this.sellType+
          '/' +
          this.classification+
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          const data = result.data.data.data;
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
   
          this.items = data 
          this.exportItems = this.items;

          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    changeClassification(name, slug) {
      this.classification = slug
      this.classificationName = name
    },
    changeSellType(name, slug) {
      this.sellType = slug
      this.sellTypeName = name
    },
    
    // deleted function to delete vendor
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete dealer.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("vendors/" + id + "/archive")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    
    // Export Active Variants For Vendor
    exportActiveVariants(item) {
     
      const current = new Date();
      const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate() + ' '+
      current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      
        axios
          .get("variants/vendors/" + item.id + "/active/export", {responseType: 'blob'})
          .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',  item.storename +  '-Products-' + date + '.xlsx');
                document.body.appendChild(link);
                link.click();

            this.$swal({
              position: "center",
              icon: "success",
              title: "downloaded success",
              showConfirmButton: false,
              timer: 1500,
            });
            
          })
          .catch((err) => {
            //console.log(err)
            this.$swal({
              position: "center",
              icon: "error",
              title: "Error!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
    
      
    },

    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].picture;
      }
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].storename,
          this.items[index].type,
          this.items[index].status,
          this.items[index].code,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [
          [
            "ID",
            "Name",
            "Store",
            "Type",
            "Status",
            "Code",
            "CREATED_AT",
            "UPDATED_AT",
          ],
        ],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
